<template><div>
   <p><b>Satchel Text Format</b></p>
<p>The <span v-html="app_name"/> system includes a powerful tool for batch-importing items in a special <strong>Satchel</strong> text format. Framework users with <span v-html="link('user_roles_admin', 'editing privileges')"></span> may use this tool. </p>
<p>When editing a framework, items can also be exported in this Satchel text format; this allows users to, for example, edit the exported output then import items using the batch tool into the same or another framework reducing the time needed to perform batch updates or imports.
	<br>
	<strong>Note:</strong>Contact us at <a href="support@commongoodlt.com">support@commongoodlt.com</a>
 to review exporting and batch importing in text format such as to determine if the IDENTIFIERS box should be checked or left unchecked.

 <ul class="k-no-clear">
	<li>Begin by selecting the EDIT icon to enable framework editing.</li>
	<li>Select an item to export the item, and optionally, also its child items, into Satchel text format</li>
	<li>Click the EDIT icon on the item card</li>
	<li>Click CREATE/COPY/IMPORT TOP-LEVEL CHILD ITEMS</li> <img srcset="/docimages/satchel_text_format.png" class="k-help-img float-right">
	<li>Select EXPORT ITEMS IN SATCHEL TEXT FORMAT</li>
	<li><strong>Data to Export:</strong> These options allow users to choose specific parts from the framework data, such as “Full statement” or “Notes”</li>
	<li><strong>Items to export:</strong> Specify whether to export only the selected item or the selected item’s descendants.</li>
	<li>Click DO IT! →</li>
	<li>A window will appear with the item (and potentially descendents) in the special <strong>Satchel</strong> text format.</li>
 </ul>
</p>
        
        </div></template>
        <script>
            import HelpMixin from './HelpMixin'
            export default {
                mixins: [HelpMixin],
            }
        </script>
        
        <style lang="scss">
        </style>