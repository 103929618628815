<template><div>
<p><strong>Print (or save as PDF)</strong>
	<br>
	<br>
	Use the Print (or save as PDF) to view, print, or save as a PDF, a selection, or the entirety of the framework.
	<ul>
		<li>Click the “<v-icon small>fas fa-print</v-icon> PRINT” icon from a <span v-html="link('document_card', 'document card')"></span> or <span v-html="link('item_card', 'item card')"></span> to open the interface shown below.</li>
		<img srcset="/docimages/print_settings.png .9x" class="k-help-img block">
		<li>The <strong>Print Settings</strong> options are on the left:  <ul>
			<li><strong>Print:</strong> Determine if you want to print the entire document (make sure to start from the document card print icon), the selected item, the selected item and its descendents, and/or document information.</li>
			<li><Strong>Item or Document Fields:</Strong> choose how much meta data you want to see and use the REFRESH PREVIEW button to update your print preview. item. </li>
			<li><strong>Font face</strong> and <strong>Font size:</strong> choose your preferred options</li>
			<li><strong>Color-code item types:</strong> uncheck option to print in black-and-white</li>
			<li><strong>Introductory Text:</strong> if you enter text here, it will be included at the start of the printout/PDF. Note that you can include images and links here.</li>
		</ul></li>
		<li>The <strong>Print Preview</strong> located on the right displays an example of how your selections will appear on paper or in PDF format. As you update <strong>Print Settings</strong>, select the REFRESH PREVIEW button.</li>
		<li>Once you are satisfied with the preview, click the PRINT button, then use your browser’s print functionality to send output to a printer or save as a PDF. </li>
		<li>To preview in a full screen view, choose the OPEN IN NEW WINDOW button. </li>
	</ul>
	<strong>Note:</strong> Some find it easier to read through a long list of standards in full screen mode.
</p>


</div></template>
<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>