<template><div>
    <p><b>Export to Canvas Spreadsheet Format</b></p>
	<p>All frameworks in Satchel can be exported to a format for easy import into Canvas. The Canvas export option allows the user to choose which parts of the selected framework to export for use as <a href="https://canvas.instructure.com/doc/api/file.outcomes_csv.html" target="_blank">Outcomes</a>. See <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-import-outcomes-for-a-course/ta-p/702" target="_blank">this Canvas help document</a> for more information. Follow the steps below to export to Canvas spreadsheet format:
	<ul>
		<li>Open a framework and navigate to the <span v-html="link('kebab_menu', 'kebab menu')"></span> in the top right corner</li>
		<li>Select <v-icon small class="fa-solid fa-file-export"></v-icon> EXPORT FRAMEWORK / COPY FRAMEWORK LINK</li>
		<li>Select EXPORT TO CANVAS SPREADSHEET FORMAT.</li>
		<li>Follow the pop-up windows of the wizard.</li>
		
		<li><strong>Step 1:</strong> To start the process, choose which parts of the framework you wish to import to Canvas, by checking the boxes in the framework tree view shown at right. Choose MOVE TO STAGE 2.</li>
		<li>Checking a box will include all child items included within the framework part selected.</li>
		<li>If you don’t check any boxes and click the “MOVE TO STAGE 2” button, the entire framework will be imported.</li>
		<li>Some parts of a framework like an appendix, introduction, mathematical practices, etc. may not be needed for Canvas outcomes.</li>
		<img srcset="/docimages/export_canvas_spreadsheet_1.png 4x" class="k-help-img">
		<img srcset="/docimages/checked_item.png 2.5x" class="k-help-img float-right">
		<li><strong>Step 2:</strong> Next, use the SAMPLE links to view the levels of the framework that you want to select to export as Outcomes for Canvas.</li>
		<img srcset="/docimages/export_canvas_spreadsheet_2.png 2.2x" class="k-help-img block">
		<li>Typically all item types will be exported as Groups except the last one or two item types at the end.
			<ul>
				<li>Usually the <strong>Outcomes</strong> are the student facing statements of learning that students would see on an assessment, gradebook, or report card</li>
			</ul>
			<p><strong>Note:</strong>At least one item type must be designated as an Outcome to MOVE TO STAGE 3.</p>
		</li>
	</ul>
	
	<ul>
		<li><strong>Step 3:</strong> Consult with your District Canvas Admin about the <Strong>Calculation Method, Mastery Points level</Strong>, and the <strong>Ratings</strong> (Rubric or Outcomes levels) being used before proceeding.<img srcset="/docimages/export_canvas_spreadsheet_3.png 2.2x" class="k-help-img block">
			<ul>
				<li><Strong>Note:</Strong> Refer to the <a href="https://canvas.instructure.com/doc/api/file.outcomes_csv.html" target="_blank">Outcomes Import Format Documentation</a> for more information related to each of the options in Step 3.</li>
			</ul>
		</li>
		<li>Select the PREPARE CSV button to see a summary of what will be exported.</li>
		
		<li><strong>Step 4:</strong> Review the summary of the rows, groups, and outcomes that will be prepared for the spreadsheet export. Use the BACK or DOWNLOAD CSV buttons as needed.
			<img srcset="/docimages/export_canvas_spreadsheet_4.png 2.2x" class="k-help-img block">
			<ul>
				<li><strong>Note:</strong> Our district testers of this feature noted that Canvas often sends a notification after the import that may not be accurate so verify what actually comes over to confirm IF any changes to the export are neede</li>
			</ul>
		</li>
	</ul>
</p>
       
       </div></template>
       <script>
           import HelpMixin from './HelpMixin'
           export default {
               mixins: [HelpMixin],
           }
       </script>
       
       <style lang="scss">
       </style>